<template>
    <div class="row">
        <div class="result-info-wrapper py-3 scrolling-column order-2 order-md-1">

            <div class="col h-100 d-flex flex-column flex-grow-1 flex-shrink-1">
                <div class="row sm-gutters mb-1 flex-shrink-0">
                    <!-- Vehicle Select -->
                    <div v-if="filteredCars && filteredCars.length" class="col-12 col-sm-6 col-lg-6 col-xl-6 mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Vehicle</span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="selectedVehicleId"
                                size="mini"
                                placeholder="Select"
                            >
                                <el-option
                                    v-for="(car, index) in [{ year: '', v_make: 'All', v_model: 'Vehicles', id: null }, ...filteredCars]"
                                    :key="index"
                                    :label="car.v_make === 'All'
                                        ? 'All'
                                        : `${Number(car.year) ? car.year : 'YEAR'} ${car.v_make ? car.v_make : 'MAKE'} ${car.v_model ? car.v_model : 'MODEL'}`"
                                    :value="car.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>

                    <!-- Vehicle Buttons -->
                    <ResultInfoButtons
                        v-if="selectedVehicle"
                        :selected-customer="selectedCustomer"
                        :selected-vehicle="selectedVehicle"
                        @update-vehicle-notes="getVehicleNotesById({ selectedDealerId: selectedDealerId, vehicleId: selectedVehicle.id })"
                    />
                </div>

                <div v-if="selectedVehicle" class="row mb-2 flex-shrink-0">
                    <div class="col-12">
                        <div class="font-weight-bolder border rounded py-2 px-3 user-details">
                            <div class="row header-info">
                                <div class="col-12 col-sm-4">
                                    <!-- VIN Number -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">VIN</span>
                                        <p>
                                            {{ selectedVehicle.VIN }}
                                            <button
                                                v-if="selectedVehicle.VIN"
                                                @click="$_copyMethods_copyText(selectedVehicle.VIN)"
                                                class="btn badge badge-alpha_neutral--shift"
                                                type="button"
                                                title="Copy vehicle VIN"
                                            ><i class="icon">file_copy</i></button>
                                        </p>
                                    </div>
                                    <!-- Result Status -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center py-1">
                                      <span class="text-uppercase">Status</span>
                                      <p>{{ selectedVehicle.repair_order_status ? selectedVehicle.repair_order_status : 'N/A' }}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <!-- Next Appointment -->
                                    <div 
                                        class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center border-bottom py-1"
                                        :style="selectedVehicle.nextAppointment.appointment_date ? { backgroundColor: '#05aaf4', color: '#ffffff' } : {}">
                                        <span class="text-uppercase text-truncate p-1">Next Appointment</span>
                                        <p class="pl-1 pr-1 flex-shrink-1 text-nowrap">
                                                {{ selectedVehicle.nextAppointment.appointment_date 
                                            ? $moment(selectedVehicle.nextAppointment.appointment_date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY @h:mm A') 
                                            : 'Not yet scheduled' }}
                                        </p>                                          
                                    </div>

                                    <!-- Service Due Date -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center py-1">
                                      <span class="text-uppercase">SERVICE DUE</span>
                                        <div class="service-due">
                                            <div v-if="selectedVehicle.nextServiceDueDate">
                                                <span class="service-due-x-small">
                                                  {{ $moment(selectedVehicle.nextServiceDueDate).format('MM/DD/YY')}}
                                                  {{ `(${$moment(selectedVehicle.nextServiceDueDate).fromNow()})`}}
                                                </span>
                                                <!-- Miles/Day -->
                                                <span class="service-due-milesday"><small>*Based on est. {{ selectedVehicle.estimated_miles_per_day }} miles/day</small></span>
                                            </div>
                                        </div>
                                        <div v-if="!selectedVehicle.nextServiceDueDate">
                                            <p>N/A</p>
                                        </div>

                                    </div>
                                  </div>
                                <div class="col-12 col-sm-4">
                                    <!-- Last Serviced Date -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">Last Serviced</span>
                                        <p>
                                            {{ selectedVehicle.last_service_date ? $moment(selectedVehicle.last_service_date).format('MM/DD/YY') : 'N/A'}}
                                            {{ selectedVehicle.last_service_date ? `(${$moment(selectedVehicle.last_service_date).fromNow()})` : ''}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Result Info Navigation -->
                <div class="d-flex justify-content-between align-items-center border-bottom flex-shrink-0">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'chat'"
                                class="nav-link active"
                                id="chat-tab"
                                data-toggle="tab"
                                href="#chat"
                                role="tab"
                                aria-controls="chat"
                                aria-selected="false"
                            >Chat</a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'messages'"
                                class="nav-link"
                                id="messages-tab"
                                data-toggle="tab"
                                href="#messages"
                                role="tab"
                                aria-controls="messages"
                                aria-selected="true"
                            >Messages</a>
                        </li>
                        <li v-if="userSession && userSession.hasCallTrackingIntegration" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', position: 'top', timeout: 250}), selectedTabId = 'phone-calls'"
                                class="nav-link"
                                id="phone-calls-tab"
                                data-toggle="tab"
                                href="#phone-calls"
                                role="tab"
                                aria-controls="phone-calls"
                                aria-selected="true"
                            >
                                Phone Call Logs
                                <span
                                    v-if="isLoading.getPhoneCallLogs"
                                    class="badge badge-pill badge-alpha_primary"
                                >
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                </span>
                            </a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'opportunities'"
                                class="nav-link"
                                id="opportunities-tab"
                                data-toggle="tab"
                                href="#opportunities"
                                role="tab"
                                aria-controls="opportunities"
                                aria-selected="false"
                            >Opportunities</a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'cases'"
                                class="nav-link"
                                id="cases-tab"
                                data-toggle="tab"
                                href="#cases"
                                role="tab"
                                aria-controls="cases"
                                aria-selected="false"
                            >Cases</a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'notes'"
                                class="nav-link"
                                id="notes-tab"
                                data-toggle="tab"
                                href="#notes"
                                role="tab"
                                aria-controls="notes"
                                aria-selected="false"
                            >
                                Notes
                                <span
                                    v-if="isLoading.getVehicleNotesById"
                                    class="badge badge-pill badge-alpha_primary"
                                >
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                </span>
                                <span
                                    v-else-if="errors.getVehicleNotesById"
                                    class="badge badge-pill badge-alpha_danger"
                                >
                                    <i class="fas fa-exclamation-triangle"></i>
                                </span>
                            </a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'communication'"
                                class="nav-link"
                                id="communication-tab"
                                data-toggle="tab"
                                href="#communication"
                                role="tab"
                                aria-controls="communication"
                                aria-selected="false"
                            >Automated Communication</a>
                        </li>
                        <li v-if="selectedVehicle" class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'vehicle-health'"
                                class="nav-link"
                                id="vehicle-health-tab"
                                data-toggle="tab"
                                href="#vehicle-health"
                                role="tab"
                                aria-controls="vehicle-health"
                                aria-selected="false"
                            >Vehicle Health</a>
                        </li>
                    </ul>

                    <button @click="$htmlToPaper(selectedTabId)" type="button" class="btn badge badge-alpha_neutral--shift"><i class="icon">print</i> Print</button>
                </div>

                <!-- Tab Box -->
                <simplebar id="simplebar_tab_content" class="scrolling-wrapper">
                    <div class="tab-content" id="myTabContent">
                        <!-- Chat -->
                        <div class="tab-pane fade py-2 px-0 show active" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                            <div class="container-fluid">
                                <template v-if="resultInfo.conversation.messages.length">
                                    <template v-for="(message, index) in resultInfo.conversation.messages">
                                        <ChatMessage
                                            :key="index"
                                            :message='message'
                                        />
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No conversation found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- SMS Chat Log -->
                        <div class="tab-pane fade py-2 px-0" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleSMSMessages.length">
                                    <SMSMessage
                                        v-for="(sms, index) in selectedVehicleSMSMessages"
                                        :key="index"
                                        :customer-initials="customerInitials"
                                        :selected-customer="selectedCustomer"
                                        :sms='sms'
                                    />
                                </template>
                                <div v-else>No messages to display</div>
                            </div>
                        </div>
                        <!-- Phone Calls Log -->
                        <ResultInfoTabPhoneCallLogs
                            v-if="userSession && userSession.hasCallTrackingIntegration"
                            :customer-phone-numbers="customerPhoneNumbers"
                            :date-from="phoneCallLogFiltersDateFrom"
                            :is-loading-get-phone-call-logs="!!isLoading.getPhoneCallLogs"
                            :phone-call-logs="phoneCallLogs"
                            :phone-numbers="phoneCallLogFiltersPhoneNumbers"
                            @updateDateFrom="(dateFrom) => phoneCallLogFiltersDateFrom = dateFrom"
                            @updatePhoneNumbers="(phoneNumbers) => phoneCallLogFiltersPhoneNumbers = phoneNumbers"
                            @getPhoneCallLogs="getPhoneCallLogs"
                        />
                        <!-- Opportunities -->
                        <div class="tab-pane fade py-2 px-0" id="opportunities" role="tabpanel" aria-labelledby="opportunities-tab">
                            <div class="container-fluid">
                                <div v-if="selectedVehicle" class="row">
                                    <div class="col">
                                        <button
                                            class="btn btn-sm btn-alpha_primary mb-2"
                                            type="button"
                                            data-toggle="modal"
                                            @click="$refs.modalOpportunity.showModal()"
                                        >Create Opportunity</button>
                                    </div>
                                </div>
                                <div v-for="(opportunity, index) in selectedVehicleOpportunitiesSorted" :key="index" class="communication communication--opportunity">
                                    <div class="communication__icon">
                                        <div class="icon">campaign</div>
                                    </div>
                                    <div class="communication__content">
                                        <div class="communication__header">
                                            <strong>Opportunity</strong>
                                            <p>{{ opportunity.opportunitiesName }}</p>
                                        </div>
                                        <div class="communication__item">
                                            {{ opportunity.assigned_user_id ? getASMNameById(opportunity.assigned_user_id) : 'None' }}
                                        </div>
                                        <div class="communication__item">
                                            <abbr v-for="(activity, index) in opportunity.activity" :key="index" :title="activity">
                                                {{ activity.charAt(0) }}
                                            </abbr>
                                        </div>
                                        <div class="communication__item d-flex">
                                            <SelectOpportunityStatus
                                                class="mr-1"
                                                :assigned-user-id="opportunity.assigned_user_id"
                                                :opportunity-id="opportunity.opp_id"
                                                :value="opportunity.sales_stage"
                                                @update-result-info="callUpdateResultInfoMethod()"
                                            />
                                            <div class="btn-group d-inline">
                                                <button class="btn btn-sm btn-alpha_bg--shift btn-flat dropdown-toggle border" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Log Action
                                                </button>
                                                <div class="dropdown-menu">
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Call').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Call</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Email').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Email</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Text').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Text</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Other').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Other</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="communication__item">
                                            <small class="white-space-nowrap">
                                                {{ $moment(opportunity.date_entered).format('MM/DD/YY @h:mm A') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Cases -->
                        <div class="tab-pane fade py-2 px-0" id="cases" role="tabpanel" aria-labelledby="cases-tab">
                            <div class="container-fluid">
                                <template v-if="!isLoading.getCases && cases.length">
                                    <div v-for="(iteratedCase, index) in cases" :key="index" class="row">
                                        <div class="col">
                                            <div class="row border p-3 m-0 mb-2">
                                                <div class="col">
                                                    <div class="d-flex align-items-center mb-4">
                                                        <div class="icon display-4 text-alpha_text mr-2">work</div>
                                                        <div style="flex-grow: 1">
                                                            <h5 class="mb-0">{{ iteratedCase.subject }}</h5>
                                                            <small class="mb-0">{{ $moment(iteratedCase.serverEnteredDate).format('MM/DD/YY @h:mm A') }}</small>
                                                        </div>
                                                        <router-link class="btn btn-sm btn-alpha_primary" :to="{ name: 'services-cases', query: { customer_id: iteratedCase.custID, vehicle_id: iteratedCase.vehicle_id, case_id: iteratedCase.case_id }}">View</router-link>
                                                    </div>
                                                    <div>
                                                        <h5>Description</h5>
                                                        <p class="card-text mb-4">{{ iteratedCase.description }}</p>
                                                        <h5>Details</h5>
                                                        <table class="table table-sm mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">VIN</th>
                                                                    <td>{{ iteratedCase.VIN }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">ASM</th>
                                                                    <td>{{ iteratedCase.assigned_user_id ? getASMNameById(iteratedCase.assigned_user_id) : 'N/A' }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Status</th>
                                                                    <td>
                                                                        {{ iteratedCase.status }} 
                                                                        {{ iteratedCase.status === 'Closed' ? $moment(iteratedCase.date).format('MM/DD/YY @h:mm A') : '' }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Priority</th>
                                                                    <td>{{ iteratedCase.priority }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Case No.</th>
                                                                    <td>{{ iteratedCase.case_number }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoading.getCases">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No cases found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- Notes -->
                        <div class="tab-pane fade py-2 px-0" id="notes" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="container-fluid">
                                <el-alert
                                    v-if="errors.getVehicleNotesById"
                                    :title="errors.getVehicleNotesById"
                                    :closable="false"
                                    class="mb-2"
                                    type="error"
                                    show-icon
                                ></el-alert>

                                <template v-if="(! isLoading.getCustomerNotesById && ! isLoading.getVehicleNotesById) && selectedVehicleAndCustomerNotes.length">
                                    <div v-for="(note, index) in selectedVehicleAndCustomerNotes" :key="index" class="communication communication--activity">
                                        <div class="communication__icon">
                                            <div class="icon">note</div>
                                        </div>
                                        <div class="communication__content">
                                            <div class="communication__header">
                                                <strong v-if="note.communicationType === 'vehicleNote'">Vehicle Note</strong>
                                                <strong v-else-if="note.communicationType === 'customerNote'">Customer Note</strong>
                                                <p>{{ note.description }}</p>
                                            </div>
                                            <div class="communication__item">
                                                {{ note.full_name }}
                                            </div>
                                            <div class="communication__item">
                                                <small class="white-space-nowrap">
                                                    {{ $moment(note.date_modified).format('MM/DD/YY @h:mm A') }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoading.getCustomerNotesById || isLoading.getVehicleNotesById">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No notes found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- Automated Communication -->
                        <div class="tab-pane fade py-2 px-0" id="communication" role="tabpanel" aria-labelledby="communication-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleAllCommunications.length">
                                    <template v-for="(communication, index) in selectedVehicleAllCommunications">
                                        <!-- SMS -->
                                        <div v-if="communication.communicationType === 'sms' && communication.generated_from === 'System'" :key="index" class="communication communication--sms">
                                            <div class="communication__icon">
                                                <div class="icon">chat</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>SMS</strong>
                                                    <p>{{ communication.text }}</p>
                                                </div>
                                                <div v-if="communication.first_name_ASM || communication.last_name_ASM" class="communication__item">
                                                    {{ communication.first_name_ASM }} {{ communication.last_name_ASM }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.DateTime).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Campaign Event -->
                                        <div v-if="communication.communicationType === 'campaignEvent'" :key="index" class="communication communication--campaign-event">
                                            <div class="communication__icon">
                                                <div v-if="communication.campaign_type === 'Email'" class="icon">email</div>
                                                <div v-else-if="communication.campaign_type === 'Opportunity'" class="icon">campaign</div>
                                                <div v-else class="icon">event</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>{{ communication.campaign_type }}</strong>
                                                    <p>
                                                        {{ communication.name }}<br>
                                                        {{ communication.event_type }}
                                                    </p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Opportunity Status -->
                                        <div v-if="communication.communicationType === 'opportunityStatus'" :key="index" class="communication communication--opportunity-status">
                                            <div class="communication__icon">
                                                <div class="icon">update</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Opportunity Status</strong>
                                                    <p>{{ communication.status }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.first_name }} {{ communication.last_name }}
                                                </div>
                                                <div v-if="communication.v_make || communication.v_model" class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No automated communications found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- Vehicle Health -->
                        <div class="tab-pane fade py-2 px-0" id="vehicle-health" role="tabpanel" aria-labelledby="vehicle-health-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleWarningsAndRecalls.results.length">
                                    <div class="row" v-for="(data, index) in selectedVehicleWarningsAndRecalls.results" :key="index">
                                        <!-- Vehicle Warnings -->
                                        <div v-if="data.infoType === 'warning'" class="col">
                                            <VehicleWarning
                                                :warning="data"
                                            />
                                        </div>
                                        <!-- Vehicle Recalls -->
                                        <div v-if="data.infoType === 'recall'" class="col">
                                            <VehicleRecall
                                                :recall="data"
                                                :key="index"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoading.getWarningsByVin">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No vehicle recalls or warnings found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </simplebar>

                <!-- Chat Composition -->
                <ChatComposition
                    :conversationId="this.resultInfo.conversation.id"
                    :sms-message="smsMessage"
                    :participants="selectedParticipants"
                    @updateParticipants="updateParticipants"
                    @updateSMSMessage="updateSMSMessage"
                    @success="updateConversationLog"
                />

            </div>

        </div>

        <!-- Customer Info -->
        <div
            class="customer-info-wrapper order-1 order-md-2 p-0"
        >
            <customer-info
                v-if="selectedCustomer"
                :asms='asms'
                :cars='resultInfo.Cars'
                :customer='selectedCustomer'
                :filtered-cars='filteredCars'
                :isLoadingSentiment='isLoading.getCustomersLastMessageSentimentById'
                :last-message-sentiment='customersLastMessageSentiment'
                :phone-numbers='customerPhoneNumbers'
                :selected-vehicle='selectedVehicle'
                :selected-vehicle-id='selectedVehicleId'
                :selected-a-s-m='selectedVehicleASM'
                @update-customer-notes="getCustomerNotesById({ selectedDealerId: selectedDealerId, customerId: selectedCustomer.ID })"
                @update-result-info="callUpdateResultInfoMethod()"
            />
        </div>

        <!-- Create Opportunity Modal -->
        <ModalOpportunity
            v-if="selectedCustomer && selectedVehicle"
            ref="modalOpportunity"
            :customer="selectedCustomer"
            :vehicle="selectedVehicle"
            @success="callUpdateResultInfoMethod()"
        />
    </div>
</template>

<script>
import ChatComposition from '@/components/services/child-components/ChatComposition.vue';
import ChatMessage from '@/components/services/child-components/ChatMessage.vue';
import ModalOpportunity from "@/components/modals/ModalOpportunity.vue";
import ResultInfoButtons from '@/components/services/child-components/ResultInfoButtons.vue';
import ResultInfoTabPhoneCallLogs from '@/components/services/child-components/ResultInfoTabPhoneCallLogs.vue';
import SelectOpportunityStatus from '@/components/services/child-components/SelectOpportunityStatus.vue';
import SMSMessage from '@/components/services/child-components/SMSMessage.vue';
import VehicleRecall from '@/components/services/child-components/VehicleRecall.vue';
import VehicleWarning from '@/components/services/child-components/VehicleWarning.vue';
import { generalMixin } from '@/mixins/generalMixin';
import { copyMethods } from '@/mixins/helperMixins';
import { servicesInfoMixin } from '@/mixins/servicesInfoMixin';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    components: {
        ChatComposition,
        ChatMessage,
        ModalOpportunity,
        ResultInfoButtons,
        ResultInfoTabPhoneCallLogs,
        SelectOpportunityStatus,
        SMSMessage,
        VehicleRecall,
        VehicleWarning,
    },

    mixins: [copyMethods, generalMixin, servicesInfoMixin],

    data() {
        return {
            selectedTabId: 'chat',
        }
    },

    computed: {
        ...mapState('global', {
            chatNotifications: 'chatNotifications',
        }),

        ...mapState('chatInfo', {
            cases: 'cases',
            customerNotes: 'customerNotes',
            customersLastMessageSentiment: 'customersLastMessageSentiment',
            errors: 'errors',
            file: 'file',
            isLoading: 'isLoading',
            phoneCallLogs: 'phoneCallLogs',
            resultInfo: 'resultInfo',
            selectedParticipants: 'selectedParticipants',
            smsMessage: 'smsMessage',
            vehicleNotes: 'vehicleNotes',
            vehicleWarnings: 'vehicleWarnings',
        }),

        ...mapGetters('chatInfo', {
            customerInitials: 'customerInitials',
            customerPhoneNumbers: 'customerPhoneNumbers',
            filteredCars: 'filteredCars',
            selectedCustomer: 'selectedCustomer',
            selectedVehicle: 'selectedVehicle',
            selectedVehicleASM: 'selectedVehicleASM',
            selectedVehicleOpportunitiesSorted: 'selectedVehicleOpportunitiesSorted',
        }),

        selectedVehicleActivityLog() {
            if (!this.resultInfo.ActivityLog) {
                return [];
            }
            return this.resultInfo.ActivityLog.filter(activity => {
                return !this.selectedVehicleId || (activity.vehicle_id == this.selectedVehicleId);
            });
        },

        selectedVehicleSMSMessages() {
            if (!this.resultInfo.SMSHistory) {
                return [];
            }
            return this.resultInfo.SMSHistory.filter(sms => {
                return !this.selectedVehicleId || !sms.vehicle_id || (sms.vehicle_id == this.selectedVehicleId && sms.status.toLowerCase() !== "approval pending");
            });
        },

        selectectedVehiclePhoneCalls() {
            if (!this.resultInfo.PhoneCalls) {
                return [];
            }
            return this.resultInfo.PhoneCalls.filter(phoneCall => {
                return !this.selectedVehicleId || (phoneCall.vehicle_id == this.selectedVehicleId);
            });
        },

        selectectedVehicleCampaignEventsLog() {
            if (!this.resultInfo.CampaignEventsLog) {
                return [];
            }
            return this.resultInfo.CampaignEventsLog.filter(event => {
                return !this.selectedVehicleId || (event.vehicle_id == this.selectedVehicleId);
            });
        },

        selectectedVehicleOpportunitiesStatusLog() {
            if (!this.resultInfo.OpportunitiesStatusLog) {
                return [];
            }
            return this.resultInfo.OpportunitiesStatusLog.filter(opportunityStatus => {
                return !this.selectedVehicleId || (opportunityStatus.vehicle_id == this.selectedVehicleId);
            });
        },

        selectedVehicleAndCustomerNotes() {
            const notes = [];

            // Push customer notes to array
            this.customerNotes.forEach(customerNote => {
                customerNote.communicationType = 'customerNote';
                notes.push(customerNote);
            });

            // Push vehicle notes to array
            this.vehicleNotes.forEach(vehicleNote => {
                vehicleNote.communicationType = 'vehicleNote';
                notes.push(vehicleNote);
            });

            // Sort notes
            // Sort automated communication array by date
            notes.sort((a, b) => {
                const aDate = a.date_modified ? a.date_modified : '';
                const bDate = b.date_modified ? b.date_modified : '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });

            return notes;
        },

        selectedCustomerPayments() {
            if (!this.resultInfo.Payments) {
                return [];
            }
            return this.resultInfo.Payments.filter(payment => {
                return !this.selectedVehicleId || (payment.vehicle_id === this.selectedVehicleId);
            });
        },

        selectedVehicleCaseLog() {
            if (!this.resultInfo.CasesLog) return [];

            return this.resultInfo.CasesLog.filter(caseLog => {
                return !this.selectedVehicleId || (caseLog.vehicle_id === this.selectedVehicleId);
            });
        },

        selectedVehicleWarningsAndRecalls() {
            const allWarningsAndRecalls = {
                results: [],
                lastThirtyDaysCount: 0,
            };

            // Push warnings to array
            this.vehicleWarnings?.forEach(warning => {
                warning.infoType = 'warning';
                warning.normalizedDate = warning.time_triggered;
                allWarningsAndRecalls.results.push(warning);
            });

            // Push vehicle recalls to array
            this.selectedVehicle?.recall?.forEach(recall => {
                recall.infoType = 'recall';
                recall.normalizedDate = recall.recall_date;
                allWarningsAndRecalls.results.push(recall);
            });

            // Sort warnings and recalls
            allWarningsAndRecalls.results.sort((a, b) => {
                const aDate = a.normalizedDate;
                const bDate = b.normalizedDate;

                return aDate && bDate
                    ? new Date(aDate) - new Date(bDate)
                    : aDate - bDate;
            });

            // Count warnings and recalls from last 30 days
            allWarningsAndRecalls.lastThirtyDaysCount = allWarningsAndRecalls
                .results
                .filter(warningOrRecall => {
                    const warningOrRecallDate = warningOrRecall.normalizedDate;
                    const thirtyDaysAgo = new Date();
                    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

                    return warningOrRecallDate
                        && new Date(warningOrRecallDate) > thirtyDaysAgo;
                })
                .length;

            return allWarningsAndRecalls;
        },

        selectedVehicleAllCommunications() {
            const allCommunications = [];

            // Push activities to array
            this.selectedVehicleActivityLog.forEach(activity => {
                activity.communicationType = 'activity';
                allCommunications.push(activity);
            });

            // Push SMS messages to array
            this.selectedVehicleSMSMessages.forEach(message => {
                message.communicationType = 'sms';
                allCommunications.push(message);
            });

            // Push opportunity status to array
            this.selectectedVehicleOpportunitiesStatusLog.forEach(opportunityStatus => {
                opportunityStatus.communicationType = 'opportunityStatus';
                allCommunications.push(opportunityStatus);
            });

            // Push campaign events to array
            this.selectectedVehicleCampaignEventsLog.forEach(event => {
                event.communicationType = 'campaignEvent';
                allCommunications.push(event);
            });

            // Push customer notes to array
            this.customerNotes.forEach(customerNote => {
                customerNote.communicationType = 'customerNote';
                allCommunications.push(customerNote);
            });

            // Push notes to array
            this.vehicleNotes.forEach(vehicleNote => {
                vehicleNote.communicationType = 'vehicleNote';
                allCommunications.push(vehicleNote);
            });

            // Push phone calls to array
            this.selectectedVehiclePhoneCalls.forEach(phoneCall => {
                phoneCall.communicationType = 'phoneCall';
                allCommunications.push(phoneCall);
            });

            // Push payments to array
            this.selectedCustomerPayments.forEach(payment => {
                payment.communicationType = 'payment';
                allCommunications.push(payment);
            });

            // Push case logs to array
            this.selectedVehicleCaseLog.forEach(caseLog => {
                caseLog.communicationType = 'caseLog';
                allCommunications.push(caseLog);
            });

            // Push phone call logs to array
            this.phoneCallLogs.forEach(phoneCallLog => {
                phoneCallLog.communicationType = 'phoneCallLog';
                allCommunications.push(phoneCallLog);
            });

            // Push other recalls to array
            if (this.selectedVehicle?.recall?.length) {
                this.selectedVehicle.recall.forEach(recall => {
                    recall.communicationType = 'vehicleRecall';
                    allCommunications.push(recall);
                });
            }

            // Push vehicle warnings to array
            this.vehicleWarnings.forEach(warning => {
                warning.communicationType = 'vehicleWarning';
                allCommunications.push(warning);
            });

            // Sort automated communication array by date
            allCommunications.sort((a, b) => {
                const aDate = a.DateTime ? a.DateTime
                    : a.date_modified_timezone ? a.date_modified_timezone
                    : a.date_modified ? a.date_modified
                    : a.start_date_time ? a.start_date_time
                    : a.displayDate ? a.displayDate
                    : a.recall_date ? a.recall_date
                    : a.time_triggered ? a.time_triggered
                    : '';
                const bDate = b.DateTime ? b.DateTime
                    : b.date_modified_timezone ? b.date_modified_timezone
                    : b.date_modified ? b.date_modified
                    : b.start_date_time ? b.start_date_time
                    : b.displayDate ? b.displayDate
                    : b.recall_date ? b.recall_date
                    : b.time_triggered ? b.time_triggered
                    : '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });

            return allCommunications;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        phoneCallLogFiltersDateFrom: {
            get() {
                return this.$store.state.chatInfo.phoneCallLogFilters.dateFrom;
            },
            set(value) {
                this.$store.commit('chatInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'dateFrom', value: value });
            }
        },

        phoneCallLogFiltersPhoneNumbers: {
            get() {
                return this.$store.state.chatInfo.phoneCallLogFilters.phoneNumbers;
            },
            set(value) {
                this.$store.commit('chatInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'phoneNumbers', value: value });
            }
        },

        selectedStatusListOpportunity: {
            get() {
                return this.$store.state.chatInfo.selectedStatusListOpportunity;
            },
            set(statusListOpportunity) {
                this.$store.commit('chatInfo/SET_SELECTED_STATUS_LIST_OPPORTUNITY', statusListOpportunity);
            }
        },

        selectedVehicleId: {
            get() {
                return this.$store.state.chatInfo.selectedVehicleId;
            },
            set(id) {
                this.$store.commit('chatInfo/SET_SELECTED_VEHICLE_ID', id);
            }
        },
    },

    watch: {
        // Perform actions when vehicle changes
        selectedVehicleId() {
            // Get vehicle notes
            this.getVehicleNotesById({ selectedDealerId: this.selectedDealerId, vehicleId: this.selectedVehicle?.id });

            // Scroll SimpleBar tab content to bottom/top on change
            if (this.selectedTabId == 'recalls') {
                this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', position: 'top', timeout: 0});
            } else {
                this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250});
            }
        },

        // Perform actions when selected vehicle SMS messages change
        selectedVehicleSMSMessages() {
            this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
            if (!this.isLoadingGlobal.markSMSNotificationsRead && this.selectedCustomer?.ID) {
                this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
            }
        },

        // Perform actions when vehicle notes change
        vehicleNotes() {
            this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
        },

        // Perform actions when SMS notifications change
        smsNotifications: {
            handler(newValue){
                if (this.selectedCustomer) {
                    this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
                }
            }, deep: true,
        },

        // Perform actions when chat notifications change
        chatNotifications: {
            handler(newValue){
                this.findAndMarkChatNotificationsRead();
            }, deep: true,
        },
    },

    created() {
        // Find and mark sms notifications as read
        if (this.selectedCustomer) {
            this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
        }

        // Find an mark chat notifications as read
        this.findAndMarkChatNotificationsRead();
    },

    mounted() {
        // Scroll message box to bottom
        this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250});

        // Get Cases
        if (this.resultInfo?.Customer?.ID) {
            const oneYearAgo = new Date();
            oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);

            const dateFromTo = {
                start: oneYearAgo,
                end: new Date(),
            };

            this.getCases({
                selectedDealerId: this.selectedDealerId,
                customerId: this.resultInfo.Customer.ID,
                dateFromTo: dateFromTo,
            });
        }

        // Get vehicle notes
        if (this.selectedVehicle && this.selectedVehicle.id) {
            this.getVehicleNotesById({ selectedDealerId: this.selectedDealerId, vehicleId: this.selectedVehicle.id });
        }

        // Get vehicle warnings by VIN
        if (this.selectedVehicle?.VIN) {
            this.getWarningsByVin({
                selectedDealerId: this.selectedDealerId,
                vin: this.selectedVehicle.VIN
            });
        }

        // Get customer notes
        if (this.selectedCustomer) {
            this.getCustomerNotesById({ selectedDealerId: this.selectedDealerId, customerId: this.selectedCustomer.ID });
        }
    },

    methods: {
        /**
         * Vuex global actions
         */
        ...mapActions('global', {
            markChatNotificationsRead: 'markChatNotificationsRead',
        }),

        /**
         * Vuex searchResultInfo actions
         */
        ...mapActions('chatInfo', {
            getCases: 'getCases',
            getCustomerNotesById: 'getCustomerNotesById',
            getPhoneCallLogs: 'getPhoneCallLogs',
            getVehicleNotesById: 'getVehicleNotesById',
            getWarningsByVin: 'getWarningsByVin',
            updateResultInfo: 'updateResultInfo',
        }),

        /**
         * Call updateResultInfo method with required parameters
         */
        callUpdateResultInfoMethod() {
            this.updateResultInfo({ selectedDealerId: this.selectedDealerId });
        },

        /**
         * Update selected participants
         */
        updateParticipants(asmIds) {
            this.$store.commit('chatInfo/SET_SELECTED_PARTICIPANTS', asmIds);
        },

        /**
         * Update SMS message in store
         */
        updateSMSMessage(message) {
            this.$store.commit('chatInfo/SET_SMS_MESSAGE', message);
        },

        /**
         * Update conversation log
         */
        updateConversationLog() {
            this.callUpdateResultInfoMethod();
            this.$store.commit('chatInfo/SET_SMS_MESSAGE', '');
            this.$emit('updateResults');
        },

        /**
         * Get ASM name by ID
         */
        getASMNameById(asmId) {
            const foundASM = this.asms.find(asm => asm.asmId === asmId);
            if (! foundASM) {
                return 'None';
            }

            let asmName = foundASM.first_name ?? '';
            asmName += foundASM.last_name ? ` ${foundASM.last_name}` : '';

            if (! asmName) {
                asmName = foundASM.asmName ?? '(ASM Name Not Provided)';
            }

            return asmName;
        },

        /**
         * Find and mark chat notifications as read
         */
        findAndMarkChatNotificationsRead() {
            const chatNotificationIds = this.chatNotifications.notifications.reduce((chatNotificationIds, chatNotification) => {
                    if (chatNotification.conversation_id !== this.resultInfo.conversation?.id) {
                        return chatNotificationIds;
                    }

                    chatNotificationIds.push(chatNotification.id);
                    return chatNotificationIds;
            }, []);

            if (chatNotificationIds.length) {
                this.markChatNotificationsRead({ ids: chatNotificationIds, selectedDealerId: this.selectedDealerId });
            }
        },
    },
}

</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
